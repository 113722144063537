import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { compose } from 'redux'
import _without from 'lodash.without'
import Immutable from 'seamless-immutable'

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

import SiteContainer from '../../components/SiteContainer'
import WithSessionHoc from '../../hocs/WithSession'
import { FeaturedTitle } from '../../components/Title'
import * as routes from '../../routes'

import WithEventsHoc from '../../hocs/WithEvents'
import WithToasterHoc from '../../hocs/WithToaster'
import { ThemesList, ThemesListItem } from '../../components/ThemesList'
import SiteHeaderBEMC2024 from '../SiteHeaderBEMC2024'
import StravaBEMCMicrosite from '../../components/StravaBEMCMicrosite'
import Input, { InputRow, Select } from '../../components/Input'
import Button, { PrimaryButton, WithProgress } from '../../components/Button'
import { colours } from '../../theme'
import { PRODUCT_SKU_DIGITAL } from '../../hocs/WithSession/constants'
import WithPrintHoc from '../../hocs/WithPrint'
import { eventThemeNames } from '../../hocs/WithThemes/model'
import {
  getFormData,
  serializeFormData,
  validateFormData,
} from '../../utils/forms'
import { SmallText } from '../../components/Text'
import Link from '../../components/Link'
import { apiPostCertificateOrder } from '../../api'

const MobilePadding = styled.div`
  @media (max-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
const ApplyCouponButton = styled(WithProgress(Button))`
  &:after {
    border-color: ${colours.grey05};
  }
`
const SubmitButton = WithProgress(PrimaryButton)

class UnstyledPage extends React.Component {
  constructor(props) {
    super(props)

    this.event = props.events.find(({ uri }) => uri === 'stravaadidas2025')
  }

  componentWillReceiveProps(nextProps) {}

  state = {
    invalidFields: [],
    type: null,
    coupon: undefined,
    shippingEstimate: undefined,
    courier: undefined,
  }

  onStravaAuthClick = (evt) => {
    evt.preventDefault()
    this.props.getStravaAuthToken()
  }

  onFocus = (fieldName) => () => {
    this.setState({
      invalidFields: _without(this.state.invalidFields, fieldName),
    })
  }

  isInvalid = (fieldName) => this.state.invalidFields.includes(fieldName)

  get isCertificateChosen() {
    return !!this.state.type
  }

  get couponHasValue() {
    return !!this.state.coupon
  }

  get originalPrice() {
    if (!this.props.currentProduct) return null
    return this.props.currentProduct.originalPrice
  }

  get canOrderCertificate() {
    // TODO valiadate form fields
    return true
  }

  onCouponChange = (event) => {
    const { value } = event.target
    this.setState({
      coupon: value.trim(),
    })
  }

  onCertificateChange = ({ value }) => {
    this.setState({
      type: value,
    })
  }

  onCountryChange = ({ products, label }) => {
    let newState = {}
    const { currentProduct } = this.props

    if (!products || !currentProduct) {
      newState = {
        shippingEstimate: null,
        courier: null,
      }
    } else if (this.isDigitalOrder) {
      newState = {
        shippingEstimate: null,
        courier: null,
      }
    } else {
      const { sku, shippingRate } = currentProduct
      const selectedProduct = products[sku]
      newState = {
        shippingEstimate: selectedProduct[shippingRate].shippingEstimate,
        courier: selectedProduct[shippingRate].courier,
      }
    }
    this.setState(newState)
  }

  couponIsDigital = (coupon = '') => coupon.match(/^STRAVA-D/i)

  couponIsPhysical = (coupon = '') => coupon.match(/^STRAVA-P/i)

  get isDigitalOrder() {
    return this.couponIsDigital(this.state.coupon)
  }

  get couponProduct() {
    if (this.couponIsDigital(this.state.coupon)) {
      return 'DIGITAL'
    } else if (this.couponIsPhysical(this.state.coupon)) {
      return 'GLOBAL-FAP-A3'
    }
    return null
  }

  get isCouponApplied() {
    if (!this.props.currentProduct) return false

    return !!this.originalPrice && !!this.props.couponId
  }

  get canRenderAddress() {
    return this.isCouponApplied && this.couponIsPhysical(this.props.couponId)
  }

  onApplyCoupon = () => {
    const product = this.couponProduct
    this.props.getCoupon(this.state.coupon, product)
    this.props.updatePrint({ product })
  }

  get countriesFilteredByCurrentProduct() {
    const { currentProduct, shippingCountries } = this.props
    const countries = Immutable.asMutable(shippingCountries)
    if (currentProduct && currentProduct.sku) {
      return countries.filter(({ products }) =>
        Object.keys(products).includes(currentProduct.sku),
      )
    }
    return countries
  }

  validateForm(formData) {
    return validateFormData(formData)
  }

  onValidationError = (error) => {
    const { message } = error
    this.props.createToast(message)

    // this.props.setOrderInProgress(false)
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    const formData = getFormData(this.form)

    const validation = this.validateForm(formData)

    if (validation.status) {
      this.setState({
        invalidFields: [],
      })
      this.postOrder(formData)
    } else {
      this.onValidationError({
        type: 'validation_error',
        message: "Oops, looks like you've left out some fields! 👆",
      })
      this.setState({
        invalidFields: validation.invalidFields,
      })
    }
  }

  renderShippingDuration() {
    if (this.isDigitalOrder) return null

    const { currentProduct } = this.props
    const { shippingEstimate, courier } = this.state
    return shippingEstimate && courier && currentProduct ? (
      <SmallText light>
        Your print will take up to{' '}
        <strong>{currentProduct.leadTime} hrs</strong> in addition to{' '}
        <strong>
          {shippingEstimate} business days via {courier}
        </strong>
        .
      </SmallText>
    ) : (
      <SmallText light>
        We'll estimate shipping times when you've selected your country.
      </SmallText>
    )
  }

  async postOrder(formData) {
    const data = serializeFormData(formData)

    // TODO: set busy state

    // Pierre, just mock/leave this commented out for now until I have
    // both physical & digital orders working.
    // const response = await apiPostCertificateOrder(data)

    // TODO: unset busy state
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTopOnMount />
        <Helmet>
          <Helmet>
            <title>Strava Adidas 100 Days Challenge</title>
          </Helmet>
        </Helmet>
        <div className={this.props.className}>
          <SiteContainer className="w-full">
            <SiteHeaderBEMC2024 />
            <MobilePadding>
              <ThemesList>
                {this.event.themes.map((theme) => (
                  <ThemesListItem
                    image={theme.image}
                    title={theme.title}
                    subtitle={theme.subtitle}
                    key={theme.themeName}
                    orientation={theme.orientation}
                    to={`${routes.CREATE}?themeName=${theme.themeName}`}
                  />
                ))}
              </ThemesList>
              <form
                noValidate
                ref={(r) => (this.form = r)}
                onSubmit={this.handleSubmit}
                style={{ maxWidth: '500px', margin: '0 auto 100px' }}
              >
                <Select
                  name="type"
                  placeholder="Select a sport"
                  options={[
                    {
                      label: 'Running',
                      value: 'running-01',
                    },
                    {
                      label: 'Cycling',
                      value: 'cycling-01',
                    },
                    {
                      label: 'Swimming',
                      value: 'swimming-01',
                    },
                    {
                      label: 'Yoga',
                      value: 'yoga-01',
                    },
                  ]}
                  onChange={this.onCertificateChange}
                />
                {this.isCertificateChosen && (
                  <div>
                    <InputRow>
                      <Input
                        name="coupon"
                        placeholder="Enter your voucher code"
                        defaultValue={
                          !!this.originalPrice && !!this.props.couponId
                            ? this.props.couponId
                            : undefined
                        }
                        onChange={this.onCouponChange}
                        onFocus={this.onFocus('coupon')}
                      />
                    </InputRow>
                    {this.couponHasValue && (
                      <ApplyCouponButton
                        busy={this.props.couponCheckInProgress}
                        disabled={this.props.couponCheckInProgress}
                        onClick={this.onApplyCoupon}
                      >
                        Apply Coupon
                      </ApplyCouponButton>
                    )}
                  </div>
                )}
                {this.isCouponApplied && this.isCertificateChosen && (
                  <div>
                    <InputRow>
                      <Input
                        required
                        name="name"
                        placeholder="Full Name"
                        onFocus={this.onFocus('name')}
                        invalid={this.isInvalid('name')}
                      />
                    </InputRow>
                    <InputRow>
                      <Input
                        required
                        name="email"
                        type="email"
                        placeholder="Your email"
                        onFocus={this.onFocus('email')}
                        invalid={this.isInvalid('email')}
                      />
                    </InputRow>
                    <InputRow>
                      <Input
                        required
                        name="phone"
                        placeholder="Phone number"
                        onFocus={this.onFocus('phone')}
                        invalid={this.isInvalid('phone')}
                      />
                    </InputRow>
                    <aside>
                      <SmallText light>
                        Your email is only used for order
                        confirmation/notifications and your phone number only
                        for delivery queries.
                      </SmallText>
                    </aside>
                    {this.canRenderAddress && (
                      <div>
                        <InputRow>
                          <Input
                            required
                            name="address_line1"
                            placeholder="Address Line 1"
                            onFocus={this.onFocus('address_line1')}
                            invalid={this.isInvalid('address_line1')}
                          />
                        </InputRow>
                        <InputRow>
                          <Input
                            placeholder="Address Line 2"
                            name="address_line2"
                          />
                        </InputRow>
                        <InputRow>
                          <Input
                            required
                            name="address_city"
                            placeholder="Town or City"
                            onFocus={this.onFocus('address_city')}
                            invalid={this.isInvalid('address_city')}
                          />
                        </InputRow>
                        <InputRow>
                          <Input
                            name="address_state"
                            placeholder="State or County"
                            onFocus={this.onFocus('address_state')}
                            invalid={this.isInvalid('address_state')}
                          />
                        </InputRow>
                        <InputRow>
                          <Input
                            required
                            name="address_zip"
                            placeholder="Postal or Zip Code"
                            onFocus={this.onFocus('address_zip')}
                            invalid={this.isInvalid('address_zip')}
                          />
                        </InputRow>
                        <InputRow>
                          <Select
                            required
                            name="address_country"
                            placeholder="Select a Country"
                            options={this.countriesFilteredByCurrentProduct}
                            onChange={this.onCountryChange}
                            onFocus={this.onFocus('address_country')}
                            invalid={this.isInvalid('address_country')}
                          />
                        </InputRow>
                      </div>
                    )}
                  </div>
                )}
                {this.canOrderCertificate && (
                  <SubmitButton
                    // TODO
                    // busy={inProgress}
                    // disabled={inProgress}
                    type="submit"
                  >
                    Order my certificate
                  </SubmitButton>
                )}
                {this.isDigitalOrder ? (
                  <SmallText light>
                    Digital prints can take{' '}
                    <Link to={`${routes.FAQ}/#where-is-my-digital-print`}>
                      up to 24hrs to arrive.
                    </Link>
                  </SmallText>
                ) : (
                  <div>{this.renderShippingDuration()}</div>
                )}
              </form>
            </MobilePadding>
          </SiteContainer>
        </div>
      </React.Fragment>
    )
  }
}

const Page = styled(UnstyledPage)`
  width: 100%;

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto 13rem;
    max-width: 22em;
  }
`

const withSession = WithSessionHoc()
const withPrint = WithPrintHoc()
const withEvents = WithEventsHoc()
const withToaster = WithToasterHoc()

export default compose(withPrint, withSession, withEvents, withToaster)(Page)
