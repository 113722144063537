import palette from './palette'
import cssStyles from './styles'
import printStyles from './print'
import activityStyles from './activity'

export default {
  id: 'stravaadidas2025light',
  name: 'Strava Adidas 100: Light',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cm6kgph5s000201sgaq91h1m9',
  cssStyles,
  palette,
}
