import React from 'react'
import { compose } from 'redux'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled, { css } from 'styled-components'
import { Switch, Route } from 'react-router-dom'

import { version } from '../../../package.json'
import manifest from '../../public/manifest.json'

import WithThemes from '../../hocs/WithThemes'
import WithToaster from '../../hocs/WithToaster'
import WithSession from '../../hocs/WithSession'
import WithAnalytics from '../../hocs/WithAnalytics'

import * as routes from '../../routes'
import { media, zIndexes } from '../../styles'
import { locationPropTypes } from '../../proptypes'

import Faq from '../Faq'
import Home from '../Home'
import About from '../About'
import Links from '../Links'
import Terms from '../Terms'
import Create from '../Create'
import Thanks from '../Thanks'
import Events from '../Events'
import Privacy from '../Privacy'
import Preview from '../Preview'
import Checkout from '../Checkout'
import FourOhFour from '../FourOhFour'
import StravaAuth from '../StravaAuth'
import EventDetail from '../EventDetail'
import StravaAdidas2025 from '../StravaAdidas2025'

import SiteFooter from '../../components/SiteFooter'
import { Toast, Toaster } from '../../components/Toaster'
import { CookiePolicy } from '../../components/CookiePolicy'

const AppToaster = styled(Toaster)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndexes.toaster};
`

const {
  REACT_APP_COMMIT_HASH,
  REACT_APP_ENV,
  REACT_APP_STRIPE_API_KEY,
  REACT_APP_SITE,
  REACT_APP_PAYPAL_ENV,
} = process.env
const stripeEnv = REACT_APP_STRIPE_API_KEY.slice(0, 7)
const paypalEnv = REACT_APP_PAYPAL_ENV.toLowerCase()

class App extends React.Component {
  removeToast = (id) => () => {
    this.props.removeToast(id)
  }

  acceptCookies = () => {
    this.props.acceptCookies()
  }

  renderToast = (toast) => (
    <Toast
      key={`toast-${toast.id}`}
      message={toast.message}
      variant={toast.variant}
      onClose={this.removeToast(toast.id)}
    />
  )

  renderCookiePolicy() {
    return ![routes.LINKS].includes(this.props.location.pathname) &&
      this.props.showCookiePolicy &&
      navigator.userAgent !== 'ReactSnap' ? (
      <CookiePolicy key="cookies-nom-nom" onClose={this.acceptCookies} />
    ) : null
  }

  render() {
    const { pathname } = this.props.location
    return (
      <div className={classnames('app', this.props.className)}>
        <Helmet
          defaultTitle={manifest.name}
          titleTemplate={`%s / ${manifest.name}`}
        >
          <meta
            name="version"
            content={`${version}.${REACT_APP_COMMIT_HASH}`}
          />
          <meta name="app-env" content={REACT_APP_ENV} />
          <meta name="stripe-env" content={stripeEnv} />
          <meta name="paypal-env" content={paypalEnv} />
          <meta name="theme-color" content={manifest.theme_color} />

          <meta property="og:locale" content="en_GB" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={REACT_APP_SITE} />
          <meta property="og:title" content={manifest.name} />
          <meta property="og:description" content={manifest.description} />
          <meta
            property="og:image"
            content={`${REACT_APP_SITE}/banner-facebook.png`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="1200" />

          <meta name="twitter:title" content={manifest.name} />
          <meta name="twitter:description" content={manifest.description} />
          <meta
            name="twitter:image"
            content={`${REACT_APP_SITE}/banner-twitter.png`}
          />
          <meta name="twitter:card" content="summary_large_image" />

          <meta name="description" content={manifest.description} />
          <meta
            name="keywords"
            content="Paper Trails, prints, strava, GPX, art, custom prints, map, poster, adventures"
          />
        </Helmet>
        <AppToaster>
          {this.props.toasts.map(this.renderToast)}
          {this.renderCookiePolicy()}
        </AppToaster>
        <main className="main">
          <Switch>
            <Route
              exact
              strict
              path={routes.STRAVA_AUTH}
              component={StravaAuth}
            />
            <Route
              path={`${routes.CREATE}/:${routes.CREATE_UUID}?`}
              component={Create}
            />
            <Route path={routes.PREVIEW} component={Preview} />
            <Route path={routes.ABOUT} component={About} />
            <Route path={routes.LINKS} component={Links} />
            <Route path={routes.EVENTS_DETAIL} component={EventDetail} />
            <Route path={routes.EVENTS} component={Events} />
            <Route
              path={routes.STRAVA_ADIDAS_100_2025}
              component={StravaAdidas2025}
            />
            <Route path={routes.FAQ} component={Faq} />
            <Route path={routes.CHECKOUT} component={Checkout} />
            <Route path={routes.THANKS} component={Thanks} />
            <Route path={routes.FOUR_OH_FOUR} component={FourOhFour} />
            <Route path={routes.TERMS_AND_CONDITIONS} component={Terms} />
            <Route path={routes.PRIVACY_POLICY} component={Privacy} />
            <Route path={routes.HOME} component={Home} exact />
            <Route component={FourOhFour} />
          </Switch>
        </main>
        {!pathname.match(routes.STRAVA_AUTH) &&
          !pathname.match(routes.LINKS) && <SiteFooter />}
      </div>
    )
  }
}

App.propTypes = {
  showCookiePolicy: PropTypes.bool,
  toasts: PropTypes.arrayOf(PropTypes.object),
  removeToast: PropTypes.func,
  acceptCookies: PropTypes.func,
  location: locationPropTypes.isRequired,
}

App.defaultProps = {
  showCookiePolicy: true,
  toasts: [],
  removeToast: () => {},
  acceptCookies: () => {},
}

const withAnalytics = WithAnalytics()
const withSession = WithSession()
const withToaster = WithToaster()
const withThemes = WithThemes()

const shouldHideFooter = (props) => {
  const { pathname } = props.location
  return pathname.includes(routes.CREATE)
    ? css`
        ${SiteFooter} {
          display: none;
        }
      `
    : null
}

export default compose(
  withAnalytics,
  withThemes,
  withSession,
  withToaster,
)(styled(App)`
  ${media.create`
    ${shouldHideFooter}
  `}
`)
