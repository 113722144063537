import { ORIENTATION_PORTRAIT } from '../../WithPrint/constants'

import darkThumb from './event-thumbs/strava-adidas-100-2025-dark.png'
import lightThumb from './event-thumbs/strava-adidas-100-2025-light.png'

export const themes = [
  {
    themeName: 'stravaadidas2025dark',
    image: darkThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'Dark Mode',
    subtitle: '',
  },
  {
    themeName: 'stravaadidas2025light',
    image: lightThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'Light Mode',
    subtitle: '',
  },
]

const themeNames = themes.map((theme) => theme.themeName)

export default {
  logo: null,
  badge: null,
  uri: 'stravaadidas2025',
  link: 'https://www.strava.com/', // TODO: update link
  linkText: 'strava.com', // TODO:
  blurb: null,
  title: 'Strava Adidas 100 Days Challenge',
  description: 'Select your event to personalise your print',
  logoMaxWidth: '39rem',
  metaTitle: null,
  metaDescription: null,
  metaImage: null,
  themes,
  themeNames,
}
