import axios from 'axios'
import _get from 'lodash.get'

const {
  REACT_APP_API_DOMAIN,
  REACT_APP_PAPERTRAILS_TOKEN_HEADER,
  REACT_APP_PAPERTRAILS_TOKEN_KEY,
  REACT_APP_DEBUG_SIMULATE_ORDER,
} = process.env

// SETUP
// ----------------
const api = axios.create({
  baseURL: REACT_APP_API_DOMAIN,
  headers: {
    [REACT_APP_PAPERTRAILS_TOKEN_HEADER]: REACT_APP_PAPERTRAILS_TOKEN_KEY,
    'Content-Type': 'application/json',
  },
})

// INTERCEPTORS
// ----------------
api.interceptors.response.use(
  (resp) => resp.data,
  (error) => _get(error, 'response.data'),
)

/**
 * GET
 * Fetch a coupon by ID
 * @param {string} couponId
 */
export const apiGetCoupon = async (couponId) =>
  couponId ? api.get(`coupons/${couponId}`) : { data: null }

/**
 * GET
 * Products data. Optionally apply a coupon to product prices
 * @param {string} couponId
 */
export const apiGetProducts = async (couponId) =>
  api.get('products', {
    params: { couponId },
  })

/**
 * GET
 * Fetch print details from the database
 * The baseURL needs to change depending on the print ID prefix
 * @param {string} printId
 */
export const apiGetPrintOrder = async (printId) => {
  let baseURL = 'https://api.papertrails.io'
  if (printId.includes('_dev')) baseURL = REACT_APP_API_DOMAIN
  if (printId.includes('_sta')) baseURL = 'https://staging.api.papertrails.io'
  return api.get(`prints/${printId}`, {
    params: {},
    baseURL,
  })
}

/**
 * GET
 * Shipping data
 */
export const apiGetShipping = async () => api.get('shipping')

/**
 * POST
 * Zero cost order
 * @param {object} print
 * @param {object} user
 */
export const apiCreateZeroCostOrder = async (print, user) =>
  api.post('orders/zero/create', {
    user,
    print,
    simulate: REACT_APP_DEBUG_SIMULATE_ORDER,
  })

/**
 * POST
 * Stripe: create order
 * @param {object} print
 * @param {string} paymentMethodId
 * @param {object} user
 */
export const apiCreateStripeOrder = async (print, paymentMethodId, user) =>
  // TODO: rename to orders/stripe/create
  api.post('orders/create', {
    user,
    print,
    paymentMethodId,
    simulate: REACT_APP_DEBUG_SIMULATE_ORDER,
  })

/**
 * POST
 * Stripe: create order
 * @param {object} print
 * @param {string|number} orderId
 * @param {string} paymentIntentId
 * @param {object} user
 */
export const apiConfirmStripeOrder = async (
  print,
  orderId,
  paymentIntentId,
  user,
) =>
  // TODO: rename to orders/stripe/confirm
  api.post('orders/confirm', {
    user,
    print,
    orderId,
    paymentIntentId,
    simulate: REACT_APP_DEBUG_SIMULATE_ORDER,
  })

/**
 * POST
 * PayPal: create order
 * @param {object} print
 * @param {string} paypalOrderId
 */
export const apiCreatePaypalOrder = async (print, paypalOrderId, coupon) =>
  api.post('orders/paypal/create', {
    print,
    coupon,
    paypalOrderId,
    simulate: REACT_APP_DEBUG_SIMULATE_ORDER,
  })

/**
 * POST
 * Save Print
 * Just a cheeky method to save some stuff locally
 * @param {object} print
 */
export const apiSavePrint = async (print) => api.post('prints', { print })


/**
 * CAMPAIGNS
 * ==========================================================================
 */
export const apiPostCertificateOrder = async (data) => {
  const response = await api.post(
    'campaigns/strava/adidas2025/certificates', {
      certificate: data,
    }
  )
  return response
}
// ===========================================================================

export default api
