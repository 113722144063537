import { takeLatest, call, put, select, all } from 'redux-saga/effects'
import _get from 'lodash.get'

import { apiGetCoupon, apiGetProducts, apiGetShipping } from '../../api'
import * as StravaApi from '../../stravaApi'
import * as routes from '../../routes'

import * as cartUtils from '../../utils/cart'

import * as ToasterActions from '../WithToaster/actions'
import * as ToasterConstants from '../WithToaster/constants'
import * as PrintActions from '../WithPrint/actions'
import * as PrintSelectors from '../WithPrint/selectors'
import * as AnalyticsActions from '../WithAnalytics/actions'
import * as Actions from './actions'
import * as Selectors from './selectors'
import * as Constants from './constants'
import config from '../../config'
import { stravaBemcMessage } from '../../components/Toaster'

const { REACT_APP_STRAVA_CLIENT_ID } = process.env

/**
 * Precursor to opening the Strava Authentication page
 *
 */
export function* getStravaAuthToken() {
  try {
    const refreshToken = yield select(Selectors.refreshTokenSelector)
    const accessTokenHasExpired = yield select(
      Selectors.accessTokenHasExpiredSelector,
    )
    if (refreshToken && accessTokenHasExpired) {
      yield put(Actions.refreshStravaAccessToken(refreshToken))
    } else {
      yield call(openStravaAuthTokenPage)
    }
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('getStravaAuthToken', error.message))
  }
}

/**
 * Changes the browser's URL to authenticate with Strava and redirect
 * back to the provided URI when successfull
 *
 */
export function* openStravaAuthTokenPage() {
  const queryParams = yield [
    `client_id=${REACT_APP_STRAVA_CLIENT_ID}`,
    'response_type=code',
    'scope=activity:read',
    'approval_prompt=auto',
    `redirect_uri=${`${window.location.origin}${routes.STRAVA_AUTH}`}`,
  ]
  window.location.href = `${
    StravaApi.STRAVA_AUTH_TOKEN_ENDPOINT
  }?${queryParams.join('&')}`
}

/**
 * Get an access token for all future API requests
 *
 */
export function* getStravaAccessToken(action) {
  try {
    const { authToken } = yield action
    yield put(Actions.setStravaAuthToken(authToken))
    const data = yield call(StravaApi.apiGetStravaAccessToken, authToken)
    yield put(Actions.setStravaAthleteProfile(data.athlete))

    yield put(
      Actions.setStravaAccessTokens(
        data.access_token,
        data.refresh_token,
        data.expires_at,
      ),
    )

    yield put(PrintActions.useStravaName())
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(
      AnalyticsActions.trackError('getStravaAccessToken', error.message),
    )
  }
}

/**
 * Refresh the access token
 *
 */
export function* refreshStravaAccessToken(action) {
  try {
    const { refreshToken } = yield action
    const data = yield call(StravaApi.apiRefreshStravaAccessToken, refreshToken)

    // refresh user details
    const athlete = yield call(StravaApi.apiGetStravaAthlete, data.access_token)
    yield put(Actions.setStravaAthleteProfile(athlete))

    yield put(
      Actions.setStravaAccessTokens(
        data.access_token,
        data.refresh_token,
        data.expires_at,
      ),
    )

    yield put(PrintActions.useStravaName())
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(
      AnalyticsActions.trackError('refreshStravaAccessToken', error.message),
    )
  }
}

/**
 * Fetches shipping and print size/pricing data from our servers
 *
 */
export function* getShippingAndProducts() {
  try {
    let couponId = yield select(Selectors.couponIdSelector)

    const { data: coupon } = yield call(apiGetCoupon, couponId)
    if (!coupon || (coupon && !coupon.active)) {
      yield put(Actions.setCouponId(undefined))
      couponId = undefined
    }

    const { shipping, products } = yield all({
      shipping: call(apiGetShipping),
      products: call(apiGetProducts, couponId),
    })

    yield put(Actions.setShippingAndProducts(shipping, products))
    const defaultProduct = yield products.find((prod) => prod.default) ||
      products[0]
    const currentProductsku = yield select(PrintSelectors.productSelector)
    // Only set the default product sku if there isn't one already in the cache
    const sku = currentProductsku || defaultProduct.sku
    yield put(
      PrintActions.updatePrint({
        product: sku,
      }),
    )
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(
      AnalyticsActions.trackError('getShippingAndProducts', error.message),
    )
  }
}

/**
 * Fetches products from the API, optionally passing in a couponId
 * which will return discounted products if the coupon is valid
 *
 */
export function* getProducts(action) {
  try {
    const { couponId } = yield action
    const products = yield call(apiGetProducts, couponId)
    yield put(Actions.setProducts(products))
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('getProducts', error.message))
  }
}

/**
 * Call the API to check/validate a coupon ID. If the coupon is valid the
 * products API is called to update the prices and the store is updated.
 * Any errors should notify the user.
 *
 */
export function* getCouponSaga(action) {
  try {
    const { couponId, printProductId } = yield action

    yield put(Actions.setCouponCheckInProgress(true))

    const { data: coupon } = yield call(apiGetCoupon, couponId)
    if (coupon) {
      if (coupon.active) {
        // user has to be a premium strava member to redeem a
        // campaign voucher
        // TODO: are we doing premium only
        // how does this work on the campaign page? We'd have to ask users to
        // auth before ordering
        //
        // if (config.stravaPremiumCouponPrefix.test(couponId)) {
        //   const athlete = yield select(Selectors.athleteSelector)
        //   const isPremium = _get(athlete, 'premium', false)
        //   if (!isPremium) {
        //     yield put(ToasterActions.createToast(stravaBemcMessage()))
        //     yield put(Actions.setCouponCheckInProgress(false))
        //     return
        //   }
        // }

        if (!!coupon.product && printProductId !== coupon.product) {
          const products = yield select(Selectors.productsSelector)
          const product = cartUtils.getProduct(products, coupon.product)
          if (product) {
            yield put(
              ToasterActions.createToast(
                `Unfortunately this voucher is only valid for "${product.title}" orders`,
                ToasterConstants.TIMEOUT_DEFAULT,
              ),
            )
          } else {
            yield put(
              ToasterActions.createToast(
                `Unfortunately this voucher isn't valid for this product`,
                ToasterConstants.TIMEOUT_DEFAULT,
              ),
            )
          }
        } else {
          // SUCCESS

          yield put(
            AnalyticsActions.trackCouponAdded(couponId, coupon.percentOff),
          )

          yield put(Actions.setCouponId(couponId))

          // since prints are custom we don't usually get multiple quantities
          // so if a coupon is added always hard code to 1
          yield put(PrintActions.setPrintQuantity(1))

          // update product prices with coupon
          const products = yield call(apiGetProducts, couponId)
          yield put(Actions.setProducts(products))

          const message = coupon.message
            ? coupon.message
            : `Nailed it! Here's ${coupon.percentOff}% off! 🎉`

          yield put(
            ToasterActions.createToast(
              `${message}`,
              ToasterConstants.TIMEOUT_DEFAULT,
            ),
          )
        }
      } else {
        yield put(
          ToasterActions.createToast(
            `Oh no! Looks like you just missed that promotion 😢`,
            ToasterConstants.TIMEOUT_DEFAULT,
          ),
        )
      }
    } else {
      yield put(
        ToasterActions.createToast(
          `Hmm, never heard of "${couponId}" before 🤔`,
          ToasterConstants.TIMEOUT_DEFAULT,
        ),
      )
    }
    yield put(Actions.setCouponCheckInProgress(false))
  } catch (error) {
    console.error(error)
    yield put(Actions.setCouponCheckInProgress(false))
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('getCouponSaga', error.message))
  }
}

export function* watchGetStravaAuthToken() {
  yield takeLatest(Constants.GET_STRAVA_AUTH_TOKEN_REQUEST, getStravaAuthToken)
}

export function* watchGetStravaAccessToken() {
  yield takeLatest(
    Constants.GET_STRAVA_ACCESS_TOKEN_REQUEST,
    getStravaAccessToken,
  )
}

export function* watchRefreshStravaAccessToken() {
  yield takeLatest(
    Constants.REFRESH_STRAVA_ACCESS_TOKEN_REQUEST,
    refreshStravaAccessToken,
  )
}

export function* watchGetShippingAndProducts() {
  yield takeLatest(
    Constants.GET_SHIPPING_AND_PRODUCTS_REQUEST,
    getShippingAndProducts,
  )
}

export function* watchGetProducts() {
  yield takeLatest(Constants.GET_PRODUCTS_REQUEST, getProducts)
}

export function* watchGetCouponRequest() {
  yield takeLatest(Constants.GET_COUPON_REQUEST, getCouponSaga)
}

export default [
  watchGetStravaAuthToken,
  watchGetStravaAccessToken,
  watchRefreshStravaAccessToken,
  watchGetShippingAndProducts,
  watchGetProducts,
  watchGetCouponRequest,
]
